// GalleryContents.js
import React, { useState } from 'react';
import { colors } from '../../colorconfig';


function GalleryContents() {
    const { gallery } = require('../../content.json');
    console.log(gallery);

    const [hoveredStep, setHoveredStep] = useState(null);

    return (
        <div className="gallery-contents" style={{ '--background-color': colors.background, '--text-color': colors.text }}>
          <h2>{gallery.title}</h2>
          <div className="gallery-steps">
            {gallery.items.map((image, index) => (
              <React.Fragment key={index}>
                <div className="step" onMouseEnter={() => setHoveredStep(index)} onMouseLeave={() => setHoveredStep(null)}>
                  <img src={image} alt={`Step ${index + 1}`} />
                  <p className="step-number">{gallery.stepText} {index + 1}</p>
                </div>
                {index < gallery.items.length - 1 && (
                  <div className={`arrow ${hoveredStep === index ? 'hovered' : ''}`}>→</div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }

export default GalleryContents;

