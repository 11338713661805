import React, { useEffect } from 'react';
import Parallax from 'parallax-js';
// import Header from './components/pages/0.Header';
import Attention from './components/pages/2.0Attention';
// import Features from './components/pages/3.Features';
// import Products from './components/pages/4.Products';
import Testimonials from './components/pages/7.Testimonials';
import MessageBox from './components/pages/8.0message';

import CTA from './components/pages/8.CTA';
import Footer from './components/pages/9.Footer';
import { EyeCatchComponent } from './components/pages/1.Eyecatch';
import Hero1 from './components/pages/3.1Hero';
import Hero2 from './components/pages/3.2Hero';
import Hero3 from './components/pages/3.3Hero';

import AboutContents from './components/pages/2.1AboutContents';
// import MoreAboutContentsAbout from './components/pages/2.2MoreAboutContents';

// import PickupContents from './components/pages/4.PickupContents';
import ProductContents from './components/pages/5.ProductContents';
import GalleryContents from './components/pages/6.GalleryContents';

import TopImage from './components/pages/TopImage';


import { colors } from './colorconfig';

import './App.css';
import content from './content.json';

const { hero1, hero2, hero3 } = content;

function App() {
  // useEffect(() => {
  //   const parallax = new Parallax(document.querySelector('.eye-catch'), {
  //     relativeInput: true,
  //     clipRelativeInput: true,
  //   });

  //   return () => {
  //     parallax.disable();
  //   };
  // }, []);

  return (
    <div style={{ backgroundColor: colors.background, color: colors.text }}>
        {/* <TopImage /> */}

      {/* <div className="eye-catch-wrapper"> */}
      <div className="top-image-wrapper">

      <TopImage />

        {/* <EyeCatchComponent /> */}
      </div>
      <div className="content-wrapper">
        {content.attention && <Attention attention={content.attention} />}
        <AboutContents />
        {/* <MoreAboutContentsAbout /> */}
        {hero1 && <Hero1 hero={hero1} />}
        {hero2 && <Hero2 hero={hero2} />}
        {hero3 && <Hero3 hero={hero3} />}
        {/* {content.features && <PickupContents features={content.features} />} */}
        {content.products && <ProductContents products={content.products} />}
        <GalleryContents />
        {content.testimonials && <Testimonials testimonials={content.testimonials} />}
        {content.message && <MessageBox message={content.message} />}
        {content.cta && <CTA cta={content.cta} />}
        <Footer />
      </div>
    </div>
  );
}

export default App;