import React from 'react';
import { colors } from '../../colorconfig';

function Testimonials({ testimonials }) {
  return (
    <section className="testimonials" style={{ backgroundColor: colors.testimonialBackground }}>
      <h2 style={{ color: colors.testimonialText }}>ユーザーの声</h2>
      {testimonials.map((testimonial, index) => (
        <div className="testimonial" key={index}>
          <img src={testimonial.image} alt={testimonial.name} className="user-image" />
          <p className="testimonial-text" style={{ color: colors.testimonialText }}>{testimonial.text}</p>
          <p className="user-name" style={{ color: colors.testimonialText }}>- {testimonial.name}</p>
        </div>
      ))}
    </section>
  );
}

export default Testimonials;