import React, { useState, useEffect } from 'react';
import './TopImage.css';

const TopImage = () => {
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    const items = ['item.png', 'item2.png', 'item3.png'];
    let currentIndex = 0;

    const changeCharacter = () => {
      currentIndex = (currentIndex + 1) % items.length;
      setSelectedItem(items[currentIndex]);
    };

    const intervalId = setInterval(changeCharacter, 8000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="top-image">
      <div className="background"></div>
      <img src={`images/${selectedItem}`} alt="Character" className="character" />
    </div>
  );
};

export default TopImage;