import React from 'react';
import { colors } from '../../colorconfig';

function Hero({ hero }) {
  const heroColors = {
    background: hero.background || colors.heroBackground,
    ribbonStart: hero.ribbonStart || colors.heroRibbonStart,
    ribbonEnd: hero.ribbonEnd || colors.heroRibbonEnd,
    text: hero.text || colors.heroText,
  };

  return (
    <div className="hero" style={{ backgroundColor: heroColors.background }}>
      <img src={hero.logo} alt="Logo" className="hero-logo" />
      <div
        className="hero-ribbon"
        style={{
          background: `linear-gradient(to bottom, ${heroColors.ribbonStart}, ${heroColors.ribbonEnd})`,
        }}
      >
        <div className="hero-content">
        <h2 style={{ color: heroColors.text }}>{hero.subCopy}</h2>
          <h1 style={{ color: heroColors.text }}>{hero.title}</h1>
        </div>
      </div>
      <div className="hero-image-container">
        <img src={hero.image} alt="Hero" className="hero-image" />
      </div>
    </div>
  );
}

export default Hero;