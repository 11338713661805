// AboutContents.js
import React from 'react';
import { colors } from '../../colorconfig';

function AboutContents() {
  const { about } = require('../../content.json');
  return (
    <div className="about-contents" style={{ '--about-background-color': colors.aboutBackground, '--text-color': colors.text }}>
      {/* <div className="about-text-container">
        <div className="about-text">
          <h1>{about.title}</h1>
          <h2 className="main-copy">{about.subtitle}</h2>
          <p className="sub-copy">{about.subCopy1}</p>
          <p className="sub-copy">{about.subCopy2}</p>
          <p className="sub-copy">{about.subCopy3}</p>
          <p className="sub-copy">{about.subCopy4}</p>

        </div> */}
        {/* <button className="stylish-button" style={{ '--button-background-color': colors.buttonBackground, '--button-text-color': colors.buttonText, '--button-hover-color': colors.buttonHover, '--button-hover-text-color': colors.textLight }}>
          {about.buttonText}
        </button> */}
      {/* </div> */}
      <div className="about-image">
        <img src="/images/about.png" alt="About Us" />
      </div>
    </div>
  );
}

export default AboutContents;
