export const colors = {
  primary: '#1A3A63', // 深みのある青
  secondary: '#F5F5F5', // 明るい灰色
  accent: '#FF6B6B', // ビビッドな赤
  text: '#333333', // 濃い灰色
  textLight: '#777777', // 中間の灰色
  background: '#FFFFFF', // 白
  buttonBackground: '#1A3A63', // 深みのある青
  buttonText: '#FFFFFF', // 白
  buttonHover: '#4A6A8E', // ボタンホバー時の色
  headerBackground: '#1A3A63', // ヘッダーの背景色
  attentionBackground: 'transparent',
  attentionText: '#2B4162', // アテンションセクションのテキスト色
  aboutBackground: '#F5F5F5', // AboutContentsの背景色を黒に設定
  moreAboutBackground: '#ffffff', // ここで新しい背景色を設定
  heroBackground: '#FFFFFF', // ヒーローセクションの背景色
  heroRibbonStart: '#1A3A63', // ヒーローリボンの開始色
  heroRibbonEnd: '#4A6A8E', // ヒーローリボンの終了色
  heroText: '#FFFFFF', // ヒーローセクションのテキスト色
  footerBackground: '#1A3A63', // フッターの背景色
  footerText: '#FFFFFF', // フッターのテキスト色
  sectionBackground: '#F5F5F5', // セクションの背景色
  sectionText: '#333333', // セクションのテキスト色
  testimonialBackground: '#FFFFFF', // 感想セクションの背景色
  testimonialText: '#333333', // 感想セクションのテキスト色
  ctaBackground: '#1A3A63', // CTAセクションの背景色
  ctaText: '#FFFFFF', // CTAセクションのテキスト色
  ctaButtonBackground: '#FFFFFF', // CTAボタンの背景色
  ctaButtonText: '#FF6B6B', // CTAボタンのテキスト色
  ctaButtonHover: '#F5F5F5', // CTAボタンのホバー時の背景色
};