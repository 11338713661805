import React from 'react';

const MessageBox = ({ message }) => {
  return (
    <div className="message-box">
      <div className="message-content">
        {message.split('\n').map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    </div>
  );
};

export default MessageBox;