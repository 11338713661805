import React from 'react';
import { colors } from '../../colorconfig';

function CTA({ cta }) {
  return (
    <section className="cta" style={{ backgroundColor: colors.ctaBackground }}>
      <h2 style={{ color: colors.ctaText }}>{cta.title}</h2>
      <button className="cta-button" style={{ backgroundColor: colors.ctaButtonBackground, color: colors.ctaButtonText, borderColor: colors.ctaButtonBackground }}>
        {cta.buttonText}
      </button>
    </section>
  );
}

export default CTA;