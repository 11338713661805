import React, { useState } from 'react';
import { colors } from '../../colorconfig';


function ProductContents() {
    const { products } = require('../../content.json');
    const [showCircle, setShowCircle] = useState(true);


    const handleMouseEnter = () => setShowCircle(false);
    const handleMouseLeave = () => setShowCircle(true);

    return (
        <div className="product-contents" style={{ '--background-color': colors.background, '--text-color': colors.text }}>
          <div className="left-container">
            <div className="product-text-area">
              <h1 className="title">{products.title}</h1>
              <p className="main-text">{products.mainText}</p>
              <p className="sub-text">{products.subText}</p>
            </div>
          </div>
          <div className="right-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="image-area">
              {products.items.map((item, index) => (
                <div key={index} className={`frame frame-${index + 1}`}>
                  <img src={item.image} alt={`Product ${index + 1}`} />
                  <div className="frame-overlay">{item.title}</div>
                </div>
              ))}
              <div className={`circle ${showCircle ? '' : 'hidden'}`}>
                <span className="circle-text">{products.circleText}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }

export default ProductContents;
