import React from 'react';
import { colors } from '../../colorconfig';

function Attention({ attention }) {
  return (
    <div
      className="attention-container"
      style={{
        '--text-color': attention.styles?.textColor || colors.attentionText,
        backgroundColor: attention.styles?.backgroundColor || colors.attentionBackground,
      }}
    >
      <div className="attention-image-container">
        <img src={attention.imagePath} alt="Attention" />
      </div>
      <div className="attention-text-container">
        <h1 style={{ color: attention.styles?.textColor || colors.attentionText }}>
          {attention.title}
        </h1>
        <h2 style={{ color: attention.styles?.textColor || colors.attentionText }}>
          {attention.subtitle}
        </h2>
        <div className="button-container">
          <button
            className="attention-button"
            style={{
              '--button-background-color': colors.buttonBackground,
              '--button-text-color': colors.buttonText,
              '--primary-color': colors.primary,
            }}
          >
            {attention.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Attention;